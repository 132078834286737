import { withPrefix } from "gatsby";
import { TFunction, useTranslation } from "gatsby-plugin-react-i18next";
import { MutableRefObject, useEffect, useState } from "react";

import { blogUrl } from "../consts/urls";

export const useRegistrationUrl = () => {
  const appUrl = process.env.APP_URL;
  const { i18n } = useTranslation();
  return `${appUrl}/registration?language=${i18n.language}`;
};

export const useSystemSearchUrl = () => {
  const appUrl = process.env.APP_URL;
  const { i18n } = useTranslation();
  return `${appUrl}/systems?language=${i18n.language}`;
};

export const useExpertSearchUrl = () => {
  const appUrl = process.env.APP_URL;
  const { i18n } = useTranslation();
  return `${appUrl}/experts?language=${i18n.language}`;
};

export const useLoginUrl = () => {
  const appUrl = process.env.APP_URL;
  const { i18n } = useTranslation();
  return `${appUrl}/login?language=${i18n.language}`;
};

export const useBlogUrl = () => {
  return blogUrl;
};

export const useDocPrefix = () => {
  const { i18n } = useTranslation();

  return withPrefix(`/docs/${i18n.language}/`);
};

export const useContactAPIUrl = () => {
  const apiUrl = process.env.APP_API_URL;
  return `${apiUrl}/contacts`;
};

export const useReportAPIUrl = () => {
  const apiUrl = process.env.APP_API_URL;
  return `${apiUrl}/reports`;
};
export const getLpUrl = () => {
  const lpUrl = process.env.GATSBY_LP_URL;
  return lpUrl;
};

export const useOnScreen = (ref: MutableRefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

// TODO: Check if there is another way to call the t: TFunction<"translation">
export const getTranslationList = (t: TFunction<"translation">, i18nKey: string) => {
  const arraySize = t(i18nKey, { returnObjects: true }).length;
  const proposalList = new Array(arraySize).fill(0).map((_, i) => `${i18nKey}.${i}`);

  return proposalList;
};

export function randStr(length: number) {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

export const useScript = (url: string, type: string | null, async: boolean, defer: boolean) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    if (type != null) {
      script.type = type;
    }
    if (async) {
      script.async = true;
    }
    if (defer) {
      script.defer = true;
    }
    document.head.appendChild(script);
  }, [url]);
};
