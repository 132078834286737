import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw, { css } from "twin.macro";

import LinkButton from "./LinkButton";

interface Props {
  isOutlined: boolean;
  label: string;
  commentFirst?: boolean;
  skipComment?: boolean;
}

const commentStyle = css`
  ${tw`mx-auto my-6 text-sm sm:text-base max-w-prose`}
`;

export const BookMeeting: React.FC<Partial<Props>> = ({ isOutlined, label, commentFirst, skipComment, ...rest }) => {
  const { t } = useTranslation();

  const comment = <p css={commentStyle}>{t("strategyMeeting.comment")}</p>;

  return (
    <div tw="mt-12 text-center" id="bookMeeting" {...rest}>
      {commentFirst && !skipComment && comment}
      <LinkButton to="/contact" tw="text-base sm:text-xl" isSecondary={!isOutlined} isOutlined={isOutlined}>
        {t(label || "strategyMeeting.bookMeeting")}
      </LinkButton>
      {!commentFirst && !skipComment && comment}
    </div>
  );
};
