import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";
import HowItWorksCards from "../how_it_works/HowItWorksCards";

const cards = css`
  ${tw`py-8 max-w-5xl mx-auto flex flex-wrap items-center`}
`;

const HowItWorksIndex: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Container {...rest}>
      <section css={cards}>
        <HowItWorksCards />
        <p tw="my-8 mx-auto text-center max-w-prose text-xl">{t("howItWorks.cardSection.weProvideAccess")}</p>
        <BookMeeting />
      </section>
    </Container>
  );
};

export default HowItWorksIndex;
